/* eslint-disable no-unused-vars */

import { db } from '../firebaseDb'

class FirebaseController {
  create(payload) {
    db.collection('uploads').add(payload).then(() => {
      console.log('successfully created!')
    }).catch((error) => {
      console.log(error)
    })
  }
  update() {

  }
}

export default new FirebaseController()