<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table :items="lib.items" :headers="lib.headers" item-key="id" single-expand :expanded.sync="expanded"
          show-expand id="items-table" :items-per-page="8" :footer-props="{
                        'items-per-page-options': [8, 8],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text':'Formulas per page'}">

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="border:solid 1px #ddd!important;">
              <v-card flat>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field outlined dense hide-details readonly label="Description" :value="item.description">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="3">
                      <v-text-field outlined dense hide-details readonly label="Claimed"
                        :value="format(item.claimed_when)"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field outlined dense hide-details readonly label="Released"
                        :value="format(item.released_when)"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field outlined dense hide-details readonly label="Filename" :value="item.fn">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field outlined dense hide-details readonly label="Keywords" :value="item.keywords">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </template>

          <template v-slot:[`item.department`]="{ item }">
            <v-chip label small outlined style="width:80px!important" :color="cat_label_color(item.department)">
              {{item.department}}</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :color="item.owned_name === whoami ? 'indigo lighten-4' : 'green lighten-4'" small tile v-bind="attrs" v-on="on" elevation="0"
                  :class="item.owned_name == whoami ? 'indigo--text text--dark-4' : 'green--text text--dark-4'"
                  :disabled="action(item)">
                  <v-icon left small v-if="item.owned_name === '' || item.owned_name == whoami">mdi-radiobox-blank
                  </v-icon>
                  <v-icon left small v-else>mdi-cancel</v-icon>
                  Actions
                </v-btn>
              </template>
              <v-list v-if="item.disposition.approved === null">
                <v-list-item v-if="item.owned==''">
                  <v-btn small text @click="claim(item)">
                    <v-icon small left>mdi-account-key-outline</v-icon>Assign To Me
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="item.owned!=''">
                  <v-btn small text @click="reassign(item)">
                    <v-icon small left>mdi-account-arrow-left-outline</v-icon>Release
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="item.owned!=''">
                  <v-btn small text @click="pre_approve(item)">
                    <v-icon small left>mdi-check</v-icon>Approve
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="item.owned!=''">
                  <v-btn small text @click="pre_reject(item)">
                    <v-icon small left>mdi-cancel</v-icon>Reject
                  </v-btn>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item>
                  <v-btn small text @click="archive(item)">
                    <v-icon small left>mdi-archive-alert-outline</v-icon>Process
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn small text @click="reset(item)">
                    <v-icon small left>mdi-lock-reset</v-icon>Reset
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <!-- uploaded when -->
          <template v-slot:[`item.uploaded`]="{ item }">{{format(item.uploaded)}}</template>

          <!-- uploaded when -->
          <template v-slot:[`item.stage`]="{ item }">
            <v-select :disabled="!(item.owned_name === whoami)"
                      @change="update_stage(item)"
                      v-model="item.stage"
                      :items="stages"
                      style="max-width:200px!important"></v-select>
          </template>

          <!-- approval -->
          <template v-slot:[`item.disposition.approved`]="{ item }">
            <div v-if="item.disposition.approved===null"></div>
            <v-chip v-else-if="item.disposition.approved == true" small label outlined color="success">Approved</v-chip>
            <v-chip v-else small label outlined color="error">Rejected</v-chip>
          </template>
          <!-- owner -->
          <template v-slot:[`item.owned_name`]="{ item }">
            <v-avatar v-if="item.owned_name!=''" color="primary" size="40" class="white--text">
              {{item.owned=='11104' ? 'AL' : 'TN'}}</v-avatar>
            <v-progress-circular v-else :value="item.await_timer" :indeterminate="item.await_timer >= 100"
              :color="await_color(item.await_timer)" size="40"></v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn small outlined text @click="create()">Upload</v-btn>
        <v-btn small outlined text @click="attach()" class="ml-2">Attach</v-btn>
        <v-btn small outlined text @click="anthony()" class="ml-2">Anthony</v-btn>
        <v-btn small outlined text @click="theresa()" class="ml-2">Theresa</v-btn>
        <v-btn small outlined text @click="allmine()" class="ml-2">All Me</v-btn>
      </v-col>
    </v-row>
    <v-footer padless fixed color="blue-grey darken-2" dark>
      <v-col cols="3" class="caption">{{ whoami }}</v-col>
    </v-footer>

    <v-dialog persistent v-model="comments.modal" width="500" @keydown.esc="comments.modal = false">
      <v-card flat>
        <v-card-title class="grey lighten-2">Comments</v-card-title>
        <v-card-text>
          <v-textarea outlined v-model="comments.text" dense class="mt-4" noresize rows="6" counter="300"
            max-length="300"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn block @click="item_action()" :color="comments.approve ? 'success':'error'">
            {{comments.approve ? 'Approve and Save' : 'Reject and Save'}}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="grey lighten-3">
          <span class="caption">Press ESC to Cancel</span>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import formatDistance from 'date-fns/formatDistance'
import format from 'date-fns/format'
import FirebaseController from '../services/FormulaLockDataService'
import { db } from '../firebaseDb'
import firebase from 'firebase'

const UPLOAD_DB = 'uploads'
const APPROVED_DB = 'approved'
const REJECTED_DB = 'rejected'

export default {
  name: 'Trst',
  data: () => ({
    stage_select: null,
    stages: [
      { text: 'New', color: '#0354A6', value: 0 },
      { text: 'Assigned', color: '#9C27B0', value: 1 },
      { text: 'In Review', color: '#009688', value: 2 },
      { text: 'In Progress', color: '#1B5E20', value: 3 },
      { text: 'Holding (TFS)', color: '#D32F2F', value: 4 },
      { text: 'Holding (Third-Party)', color: '#D32F2F', value: 5 },
      { text: 'Holding (Advisor)', color: '#D32F2F', value: 6 },
      { text: 'Pending', color: '#607D8B', value: 7 },
      { text: 'Complete', color: '#4CAF50', value: 8 },
    ],
    comments: {
      modal: false,
      text: '',
      item: null,
      approve: false,
    },
    expanded: [],
    uploads: [],
    whatshappening: '',
    subscriber: null,
    whoami: 0,
    firebase_connected: false,
    filename: '',
    lib: {
      headers: [
        { text: "Department", align: "left", sortable: true, value: "department" },
        { text: "Category", align: "left", sortable: true, value: "category" },
        { text: "Owner", align: "left", sortable: true, value: "owned_name" },
        { text: "Uploaded", align: "left", sortable: true, value: "uploaded" },
        { text: "Stage", align: "center", sortable: false, value: "stage" },
        { text: "Approved", align: "center", sortable: false, value: "disposition.approved" },
        { text: '', value: 'actions', sortable: false, width: 135, divider: true },
      ],
      items: [],
    },
    obj: {
        cat: 'Securities',
        fn: 'naf.pdf',
        uploaded: '',
        desc: 'Pershing New Account Form',
        by: 'chrisgraziano@ffpinc.com',
        member: '',
        d: '',
        p: '/uploads/gmerideth/2022/2/12',
        claimed: '',
        disposition: '',
        await_timer: 0,
    },
    db: null,
    credentials: null,
    claim_timer: null,
    cat_color: [
      { t:'Securities', c:'red' },
      { t:'Insurance', c:'green' },
      { t:'Mortgage', c:'blue' },
      { t:'Taxes', c:'indigo' },
      { t:'Advertising', c:'purple' },
     ]
  }),
  mounted: function () { },
  computed: {
    users_uid () {
      return `User ID ${this.credentials?.uid ?? ''}`
    },
  },
  deactivated() {
    clearInterval(this.claim_timer)
    this.claim_timer = null
    console.log('deactivated::timer_cleared')
  },
  beforeDestroy() {
    if(this.subscriber) this.subscriber()
    clearInterval(this.claim_timer)
    this.claim_timer = null
    console.log('beforeDestroy::timer_cleared')
  },
  methods: {
    cat_label_color (i) {
      let val
      try {
       let v = this.cat_color.findIndex(r => r.t === i)
        val = this.cat_color[v].c
      } catch {
        val = 'black'
      }
      return val
    },
    v_or_e(v) {
      return v < 100 ? v : 'E'
    },
    format(t) {
      if(t === null || t === undefined) return ''
      return format(t, 'HH:mm MM/dd')
    },
    await_color (value) {
      return value < 40 ? 'green' : (value >= 40 && value <= 80) ? 'amber' : 'red'
    },
    tick () {
      clearInterval(this.claim_timer)
      if(this.firebase_connected) {
        this.claim_timer = setInterval(function () { this.update_time_left_progress() }.bind(this), 15 * 1000)
        console.log('::timer_set')
      }
    },
    update_time_left_progress() {
      if(this.lib.items.length)
        this.lib.items.forEach(i => {
          if(i.await_timer <= 100 && i.disposition.approved === null) {
            i.await_timer = Math.round(((Date.now() - i.uploaded) / 1000) / 432, 1)
          }
        })
    },
    anthony () {
      this.whoami = 'alopez'
      this.firebase_connected = true
    },
    theresa () {
      this.whoami = 'tnist'
      this.firebase_connected = true
    },
    action (i) {
       return i.owned !='' && i.owned_name != this.whoami
    },
    distance(t) {
      var d = new Date(t)
      return formatDistance(d, new Date(), { addSuffix: true })
    },
    attach () {
      db.collection(UPLOAD_DB).orderBy('uploaded').onSnapshot(this.snap)
      this.tick()
      this.update_time_left_progress()
    },
    async archive (item) {
      let dest
      const source = db.collection(UPLOAD_DB).doc(item.id)
      if(item.disposition.approved) {
         dest = db.collection(APPROVED_DB)
      } else {
        dest = db.collection(REJECTED_DB)
      }
      const doc = await source.get()
      dest.add(doc.data()).then(()=>{
        source.delete()
        console.log('moved')
      }).catch((e) => {
        console.log('failed to archive...' + e)
      })
    },
    allmine () {
      this.lib.items.forEach(async (v) => {
        if(v.claimed==='') {
          const claim = db.collection(UPLOAD_DB).doc(v.id)
          await claim.update({member: '11104', whoami: this.whoami, initials: this.whoami.substring(0,2), d: true, claimed: Date.now()})
        }
      })
    },
    async reassign (item) {
      let  claim = db.collection(UPLOAD_DB).doc(item.id)
      await claim.update( { owned: '', owned_name: '', initials: '', released_when: Date.now() } )
    },
    async update_stage(item) {
      let claim = db.collection(UPLOAD_DB).doc(item.id)
      await claim.update({ stage:  item.stage })
    },
    async item_action() {
      console.log('::item_action ' + this.comments.item)
      let d = Date.now()
      let fd = this.format(d)
      let claim = db.collection(UPLOAD_DB).doc(this.comments.item)
      console.log(this.comments.text.split('\n').join('|'))
      await claim.update( { disposition: { approved: this.comments.approve, when: Date.now(), comment: this.comments.text.split('\n').join('|') } })
      this.comments.modal = false
      this.comments.text = ''
    },
    async pre_approve (item) {
      let d = Date.now()
      let fd = this.format(d)
      this.comments.item = item.id
      this.comments.text = `Approved by ${item.initials === 'AL' ? 'Anthony Lopez' : 'Theresa Nist'} on ${fd}\n`
      this.comments.modal = true
      this.comments.approve = true
    },
    async pre_reject (item) {
      let d = Date.now()
      let fd = this.format(d)
      this.comments.item = item.id
      this.comments.text = `Rejected by ${item.initials === 'AL' ? 'Anthony Lopez' : 'Theresa Nist'} on ${fd}\n`
      this.comments.modal = true
      this.comments.approve = false
    },
    async reset (item) {
      const claim = db.collection(UPLOAD_DB).doc(item.id)
      await claim.update( { disposition: { approved: null, when: '', comment: '' }, reset_when: Date.now() })
    },
    async claim (item) {
      const claim = db.collection(UPLOAD_DB).doc(item.id)
      let o = this.whoami == 'alopez' ? '11104' : '11105'
      let i = this.whoami == 'alopez' ? 'AL' : 'TN'
      await claim.update( { owned: o, owned_name: this.whoami, initials: i, claimed_when: Date.now(), stage: 1 })
    },
    snap (items) {
      this.lib.items.forEach(v => v.life = false) // set all rows to expire
      items.forEach((d) => {
        let i = this.lib.items.find(p => p.id == d.id) ?? 0
        // not in the collection so add it to the table
        if(i === 0) { this.lib.items.push({life: true, id: d.id, ...d.data()}) }
        else {
          if(typeof i === 'object') {
            let rec = d.data()
            rec.id = d.id
            // update the record only if any of it's data has changed
            if(!this.deepEqual(i, rec)) {
              let idx = this.lib.items.findIndex(r=>r.id === d.id)
              Object.assign(this.lib.items[idx], rec)
              this.lib.items[idx].life = true
            }
          }
        }
      })
      this.lib.items = this.lib.items.filter(f => f.life)
      this.update_time_left_progress()
    },
    create () {

    },
    deepEqual(object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)
      if (keys1.length !== keys2.length) return false
      for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = this.isObject(val1) && this.isObject(val2)
        if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) { return false }
      }
      return true
    },
    isObject(object) { return object != null && typeof object === 'object' }
  }
}
</script>

<style scoped>
  >>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content { -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
</style>
