import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAEG5H8YoohHZyQdK2ZWgdR98xtraDEtyU",
    authDomain: "tfs-app-portal.firebaseapp.com",
    projectId: "tfs-app-portal",
    storageBucket: "tfs-app-portal.appspot.com",
    messagingSenderId: "419673448448",
    appId: "1:419673448448:web:4f9ed1a381990e4f2838e5"
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
export const db = firebaseApp.firestore()
