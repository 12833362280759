<template>
  <v-container fluid>

    <v-alert color="#C51162" dark icon="mdi-magnify" border="left" v-if="pk_search.advanced_search_active">
      You are currently viewing the results from the advanced search. To return to the standard view, click the refresh icon in the toolbar.
    </v-alert>

    <v-toolbar flat>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" text outlined color="white" v-bind="attrs" v-on="on" class="blue darken-2"
            @click="start_new_formulation()" v-shortkey="['ctrl','alt','n']" @shortkey="start_new_formulation()">NEW
            <v-icon small right color="white">mdi-folder-plus</v-icon>
          </v-btn>
        </template>
        <span>Create a new formula</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" text outlined v-bind="attrs" v-on="on" class="blue darken-2 ml-1"
            @click="request_formulation_list()">
            <v-icon color="white">mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <!-- -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" v-bind="attrs" v-on="on" text outlined class="blue darken-2 ml-1"
            @click="export_list_excel()">
            <v-icon color="white">mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Export list to Excel</span>
      </v-tooltip>
      <!-- -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" v-bind="attrs" v-on="on" text outlined class="blue darken-2 ml-1" @click="search.dialog_active = !search.dialog_active">
            <v-icon color="white">mdi-table-row-plus-before</v-icon>
          </v-btn>
        </template>
        <span>Item/Core Search</span>
      </v-tooltip>
      <!-- -->
      <v-spacer></v-spacer>
      <v-text-field v-model="formula_table.search" light outlined dense append-icon="mdi-magnify"
        label="Search Recent Formulas" hide-details clearable></v-text-field>
      <!-- advanced search -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" v-bind="attrs" v-on="on" text outlined class="blue darken-2 ml-4" color="white"
            @click="$refs.advancedsearch.open()">Advanced<v-icon right>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Advanced Search</span>
      </v-tooltip>
    </v-toolbar>

    <v-row dense no-gutters>
      <v-col cols="12">
        <v-data-table id="formula_table" :headers="formula_table.headers" :items="formula_table.items"
          :loading="formula_table.loading" :search="formula_table.search" v-model="formula_table.selected"
          dense @dblclick:row="editFormula" @click:row="highlight_row_click"
          single-select :items-per-page="20" item-key="fid" :footer-props="{
                        'items-per-page-options': [20,20],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-arrow-left',
                        nextIcon: 'mdi-arrow-right',
                        'items-per-page-text':'Formulas per page'}">

          <!-- formula number, formatted -->
          <template v-slot:[`item.fid`]="{ item }">
            <span v-if="item.archive_id == null"
              :class="item.by == $store.getters.userPID ? 'green--text' : 'black--text'">{{item.fid}}</span>
            <span v-else class="purple--text text--darken-2">{{item.archive_id}}</span>
          </template>
          <!-- keywords -->
          <template v-slot:[`item.category`]="{ item }">
            <v-menu offset-y offset-x open-on-hover v-if="item.category != '0'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">{{item.category == null ? 'Missing' : item.category}} <v-icon
                    right color="blue" small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(i, index) in combined_categories(item.subcategory)" :key="index" dense>
                  <v-list-item-title>
                    <v-icon small left color="grey" v-text="i.icon"></v-icon> {{ i.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else text disabled small>No Category</v-btn>
          </template>
          
          <!-- description -->
          <template v-slot:[`item.description`]="{ item }">
            <v-chip class="no_selection" color="grey" label outlined x-small>{{item.First[0]}}{{item.Last[0]}}</v-chip>
            <v-chip v-if="item.editing_by" class="ml-1 no_selection" color="red" outlined x-small label>open</v-chip>
            <span class="no_selection ml-1">{{trim_string(item.description)}}</span>
          </template>

          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <!-- clone action -->
            <v-tooltip top open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="confirm_clone_action(item)" v-bind="attrs" v-on="on" color="blue">mdi-content-duplicate</v-icon>
              </template>
              <span>Clone Formula</span>
            </v-tooltip>
            <!-- lock action -->
            <v-icon v-if="!item.locked" class="ml-2" :disabled="item.editing_by!=null" @click="lock_formula(item)" color="blue">mdi-lock-open</v-icon>
            <v-icon v-else-if="item.lockedby == $store.getters.userPID" :disabled="item.editing_by!=null" @click="unlock_formula(item)" class="ml-2 blue--text">mdi-lock</v-icon>
            <v-icon v-else class="ml-2 red--text text--lighten-2">mdi-lock</v-icon>
            <v-icon v-if="item.is_shipped" class="ml-2 green--text">mdi-truck</v-icon>
            <v-icon class="ml-2 grey--text" @click="view_formula(item)">mdi-eye-outline</v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- system bar legend -->
    <v-system-bar height="40" lights-out>
      <v-icon small class="red--text">mdi-lock</v-icon> <span class="caption">Locked</span>
      <v-icon small class="ml-2 blue--text">mdi-lock</v-icon> <span class="caption">By You</span>
      <v-icon small class="ml-2 green--text">mdi-truck</v-icon> <span class="caption">Shipped</span>
      <v-divider class="ml-2" vertical inset></v-divider>
      <span class="caption ml-2">Double click formula row to edit.</span>
    </v-system-bar>

    <!-- new formulation dialog -->
    <v-dialog v-model="new_formulation_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <v-row dense>
            <v-col cols="7"> <span class="text-h6">Formula Created</span></v-col>
            <v-col cols="5" class="d-flex justify-end">
              <v-text-field dense v-model="new_formulation.number" @click:append="copy_number_clipboard"
                @click="copy_number_clipboard" persistent-hint :hint="copying_hint" :loading="copying_number"
                append-icon="mdi-clipboard-file-outline" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field dense v-model="new_formulation.description" label="Description" hint="Product description"
                persistent-hint counter="100" maxlength="100" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field dense v-model="new_formulation.company" label="Company" counter="35" maxlength="35" required
                hint="Leave blank for no company" persistent-hint></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="category_tags" :items="category_items" label="Select Category" hide-details
                ref="category">
                <template v-slot:selection="{ item, index }">
                  <v-chip label small v-if="index === 0" outlined class="blue--text">
                    <v-icon left>mdi-label</v-icon>{{ item }}
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">(+{{ value.length - 1 }} others)</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="keyword_tags" auto-select-first chips clearable deletable-chips multiple
                :items="keyword_items" @change="adv_search=''" label="Keywords" hide-details dense ref="keywords"
                :search-input.sync="adv_search">
                <template v-slot:selection="{ item }">
                  <v-chip label small outlined class="blue--text">
                    <v-icon left>mdi-label</v-icon>{{ item }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select v-model="new_formulation.type" label="Formula Type"
                :items="[ { text: 'Grams/Litre', value: 0 }, { text: 'Percent Basis', value: 1 } ]"
                @change="clear_measurement_field()" dense class="green--text green--lighten-2">
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select v-model="new_formulation.measurement" dense v-if="new_formulation.type===1"
                :items="[ { text: 'Weight/W', value: 0 }, { text: 'Volume/V', value: 1 }, { text: 'Mixed Units', value: 2 } ]" persistent-hint
                hint="Select the Unit of Measurement"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="background-color:#f4f4f4;">
          <v-btn outlined color="error" text @click="verify_close()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined color="success" text @click="save_new_formulation()">Begin Editing</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog -->
    <v-dialog v-model="search.dialog_active" max-width="550">
      <v-card flat>
        <v-subheader class="body-1 blue--text">Item Core Lookup <v-divider inset></v-divider></v-subheader>
        <v-card-text class="pt-2">
          <v-text-field v-model="search.search" label="PK/R# Expression"
                        hide-details clearable
                        v-on:keyup.enter="componentQuery" ref="expression"
                        outlined class="upper"></v-text-field>
          <v-text-field v-model="search.filter" label="Additional Results Filter" class="mt-2"
                        dense hide-details clearable outlined></v-text-field>
        </v-card-text>
      </v-card>
      <v-card max-height="400" flat>
        <v-card-text>
          <v-data-table :headers="search.headers" v-model="search.selected" :items="search.items"
                        :dense="search.dense" :search="search.filter" item-key="value"
                        hide-default-footer :loading="search.busy"
                        :footer-props="{ 'items-per-page-options': [-1] }">
          </v-data-table>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="grey lighten-4"> <span class="caption">Press ESC to close window</span> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn small text outlined class="float-right" @click="clear_locks">Clear Locks (debug)</v-btn>
    <AdvancedSearchDialog ref="advancedsearch" v-on:update="update_query_results" />
    <!-- confirmation dialog component -->
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import axios from 'axios'
import { __category_items, __keywords} from './data/arrays/categories'
import { helperMixin } from '../mixins/helper'
import { EventBus } from '../components/EventBus.js'
import FormulaLockDataService from '../services/FormulaLockDataService'

const rowNewLine = () => {
  return '\r\n'
}

export default {
  name: "Formulations",
  components: {
    AdvancedSearchDialog: () => import('./dlgAdvancedSearch'),
    ConfirmDlg: () => import('./dlgConfirm')
  },
  mixins: [ helperMixin ],
  watch: {
    category_tags() { this.$refs.category.setMenuIndex(0) },
    keyword_tags() { this.$refs.keywords.setMenuIndex(0) },
  },
  data: () => ({
    search: {
      single_select: true,
      selected: [],
      dialog_active: false,
      headers: [
        { text: 'Item', align: "left", sortable: true, value: 'text', class: "font-weight-bold", divider: true },
        { text: 'Code', align: "left", sortable: true, value: 'value', class: "font-weight-bold" },
        { text: 'Cost', align: "left", sortable: true, value: 'cost', class: "font-weight-bold" },
        { text: 'Date', align: "left", sortable: true, value: 'last' },
      ],
      items: [],
      search: '',
      filter: '',
      dense: true,
      busy: false,
    },
    adv_search: '',
    locks: [],
    pk_search_sync: null,
    pk_search: {
      advanced_search_active: false,
      results: 'No Results',
      model: null,
      items: [],
      timer: null,
      loading: false,
      error_messages: [],
      error: false,
    },
    display_empty: true,
    total_formulas: 0,
    saveedit: {},
    new_formulation_dialog: false,
    category_tags: [],
    category_items: __category_items,
    keyword_tags: [],
    keyword_items: __keywords,
    formula_table: {
      expanded: [],
      singleSelect: true,
      selected: [],
      headers: [
        { text: "Number", align: "left", sortable: true, value: "fid", divider: true },
        { text: "Description", align: "left", sortable: false, value: "description" },
        { text: "Company", align: "left", sortable: false, value: "company" },
        { text: "Keywords", align: "left", sortable: true, value: "category" },
        { text: "Brix", align: "left", sortable: true, value: "brix", divider: true },
        { text: "Actions", value: 'actions', sortable: false, width: 135, divider: true, align: 'center' },
      ],
      items: [],
      pre_filter_items: [],
      loading: false,
      search: '',
      dense: true,
    },
    formulation: {
      autocomplete: [],
      disabled: false,
      items: [],
      loading: false,
      sync: ''
    },
    save_formulation_obj: null,
    new_formulation: {
      idx: 0,
      loading: false,
      number: '',
      description: '',
      company: '',
      measurement: '',
      type: ''
    },
    copying_number: false,
    copying_hint: 'Copy to Clipboard'
  }),
  mounted: function () {
   this.store_display_brix = this.$store.getters.display_brix
   this.request_formulation_list()
   this.save_formulation_obj = this.new_formulation
  },
  computed: {
  },
  beforeDestroy() { },
  methods: {
    view_formula(i) {
      this.$router.push(`/formulations/viewer/${i.idx}`)
    },
    export_excel_headers () {
      let row = `"Number","Description","Company","Category","Keywords","Brix","Locked?","Shipped?","Creator"`
      return row + rowNewLine()
    },
    export_excel_rows () {
      let row = ''
      for (var i in this.formula_table.items) {
        let p = this.formula_table.items[i]
          row += `'${p.fid}','${p.description}','${p.company}','${p.category}','${p.subcategory}','${p.brix}','${p.is_shipped === null ? 'False' : 'True'}','${p.First} ${p.Last}',`
          row +=  rowNewLine()
      }
      return row
    },
    export_list_excel () {
      if(this.formula_table.items.length === 0) {
         EventBus.$emit('error', { message: 'Nothing to export' })
         return
      } else {
        this.formula_table.loading = true

        var CSVOUT = 'sep=,' + '\r\n'

        CSVOUT += this.export_excel_headers()
        CSVOUT += this.export_excel_rows()

        // build the link filename, hidden <A> and send it
        try {
          let fileName = `${this.$store.getters.name}_Formulation_List`.replace(/ /g, "_")
          let uri = 'data:text/csv;charset=utf-8,' + escape(CSVOUT)
          var link = document.createElement("a")
          link.href = uri
          link.style = "visibility:hidden"
          link.download = fileName + ".csv"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } catch (err) {
          EventBus.$emit('error', { message: 'Export failed, Error 102' })
        }

        this.formula_table.loading = false
      }
    },
    combined_categories (sub) {
      let p = []
      if(sub === null) p.push({text: 'Missing', icon: 'mdi-circle-medium'})
      else sub.split(',').sort().forEach((r)=>p.push({text: r, icon: 'mdi-circle-medium'}))
      return p
    },
    update_query_results (results) {
      this.pk_search.advanced_search_active = true
      this.formula_table.items = results
    },
    clear_locks() {
      axios.get(`${this.$store.getters.NODEURL}/formula/editing/releaseall`, { withCredentials: true })
           .then(() => { EventBus.$emit('notify', { message: `Cleared Locks - Refresh Formulas` }) })
           .catch(() => { EventBus.$emit('error', { message: 'Failed to Cleared Locks' }) })
    },
    editing_by_whom(item) {
      let p = this.locks.find(r => r.fid == item.fid)
      if(p === undefined) return ''
      if(p.by === this.$store.getters.userPID) return 'You'
      else return p.name
    },
    on_lock_change(items) {
      let _locks = []
      // reset all locked flags
      this.formula_table.items.forEach(v => v.editing_by = null)
      // apply new lock flags
      items.forEach((item)=>{
        let _i = item.data()
        console.log(_i)
        console.log(item.id)
        // push the firebase entries
        _locks.push({fid: item.id, by: _i.by, when: _i.when, name: _i.name })
        // if it's in the current users view list
        let v = this.formula_table.items.find(r => r.fid == item.id)
        // flag it as being edited
        if(v != undefined) v.editing_by = _i.by
      })
      this.locks = _locks
    },
    async test(item) {
      let lock = { by: this.$store.getters.userPID, when: Date.now(), name: this.$store.getters.initial_name }
      let o = this.locks.find(r => r.fid == item.fid)
      if(o == undefined) {
        FormulaLockDataService.create(item.fid, lock)
        let v = this.formula_table.items.find(r => r.fid == item.fid)
        if(v != undefined) v.editing_by = this.$store.getters.userPID
      } else {
        console.log('that formula is already locked')
      }
    },
    search_pk_values(value) {
      this.pk_search.loading = true
      this.pk_search.error_messages = []
      this.pk_search.items = []
      axios.post(`${this.$store.getters.NODEURL}/formula/com/query`, { query: value }, { withCredentials: true })
        .then(response => {
          // console.log(response.data.results.length)
          if(response.data.results.length > 0) {
            let i = 0
            this.pk_search.items = response.data.results.map(r => ({ index: i++, value: r.value, text: r.text, cost: r.cost }))
            this.pk_search.results = `${this.pk_search.items.length} Results`
          } else {
            this.pk_search.model = ''
            this.pk_search.results = `No Results`
          }
        })
        .catch(e => {
          console.error(e)
          EventBus.$emit('error', { message: `Search Failed - 52` })
          this.pk_search_items = []
          this.pk_search.error_messages.push('database error')
        })
        .finally(() => { this.pk_search.loading = false })
    },
    copy_number_clipboard () {
      this.copying_number = true
      this.$copyText(this.new_formulation.number, document.querySelector('.v-dialog'))
      setTimeout(() => {
        this.copying_number = false
        this.copying_hint = 'copied to clipboard'
      }, 750)
    },
    clear_measurement_field () {
      if(this.new_formulation.type === 0) {
        this.new_formulation.measurement = null
      }
    },
    revert_table_results () {
    },
    highlight_row_click (item, row) {
      row.select(true)
    },
    hide_show_empty() {
      if(this.display_empty) {
        this.formula_table.items = this.formula_table.pre_filter_items
      } else {
        this.formula_table.pre_filter_items = this.formula_table.items // backup the items set
        this.formula_table.items = this.formula_table.items.filter(i => i.description != '')
      }
      this.display_empty = !this.display_empty
    },
    switch_brix () {
      // this.store_display_brix = !this.store_display_brix
      this.$store.commit("flap_brix")
    },
    trim_string(payload) {
      let p = payload.length
      // if the brix column is showing trim the names a little shorter
      let c = this.store_display_brix ? 45 : 65
      return p <  c ? payload : `${payload.substring(0,  c)}...`
    },
    full_formula_number(item) {
      return `${item.number}${item.sequence}-${item.variant.toString().padStart(2, "0")}`
    },
    unlock_formula(item) {
      this.formula_table.loading = true
      axios.post(`${this.$store.getters.NODEURL}/formula/unlock/${item.idx}`, { item }, { withCredentials: true })
        .then((res) => {
          if(res.data.result) {
            item.locked = false
            item.lockedby = null
            EventBus.$emit('notify', { message: `Formula Unlocked` })
            EventBus.$emit('activity', { message: `Unlocked ${this.full_formula_number(item)}` })
          }
        })
        .catch((err) => {
          console.log(err)
          EventBus.$emit('error', { message: 'Failed to Unlock Formula - 22' })
        })
        .finally(() => { this.formula_table.loading = false })
    },
    lock_formula (item) {
      this.formula_table.loading = true
      item.locked = true
      item.lockedby = this.$store.getters.userPID
      axios.post(`${this.$store.getters.NODEURL}/formula/lock/${item.idx}`, { item }, { withCredentials: true })
        .then(() => {
           EventBus.$emit('notify', { message: `Formula Locked` })
           EventBus.$emit('activity', { message: `Locked ${this.full_formula_number(item)}` })
        })
        .catch((err) => {
          console.log(err)
          EventBus.$emit('error', { message: 'Failed to Lock Formula - 22' })
        })
        .finally(() => { this.formula_table.loading = false })
    },
    save_new_formulation() {
      this.new_formulation.category = this.category_tags
      this.new_formulation.sub = this.keyword_tags.join(',')
      axios.post(`${this.$store.getters.NODEURL}/formula/update`, { item: JSON.stringify(this.new_formulation) }, { withCredentials: true })
        .then(() => {
            this.editFormula(this.new_formulation)
          })
        .catch((err) => {
          console.log(err)
           })
        .finally(() => {
           this.new_formulation = this.save_formulation_obj
        })
    },
    verify_close () {
      this.new_formulation = this.save_formulation_obj
      this.new_formulation_dialog = false
    },
    start_new_formulation () {
      this.new_formulation.loading = true
      axios
        .get(`${this.$store.getters.NODEURL}/formula/number`, {  withCredentials: true })
        .then((response) => {
          if(response.data.result) {
            EventBus.$emit('notify', { message: `New Formula Created` })
            EventBus.$emit('activity', { message: `Created Formula ${response.data.results}` })
            this.new_formulation_dialog = true
            this.new_formulation.number = response.data.results
            this.new_formulation.idx = response.data.idx
          } else {
            EventBus.$emit('error', { message: 'Failed to Create Formula - 23' })
          }
        })
        .catch((err) => { console.log(err) })
        .finally(() => { this.new_formulation.loading = false })
    },
    request_formulation_list() {
      this.formula_table.loading = true
      axios
        .get(`${this.$store.getters.NODEURL}/formulations/all`, {  withCredentials: true })
        .then((response) => {
          let p = response.data
          if(p?.results?.length > 0) {
             let __data = this.create_pid_from_array(p.results)
             this.formula_table.items = __data
             this.formula_table.pre_filter_items = __data
             this.pk_search.advanced_search_active = false
          } else {
            EventBus.$emit('error', { message: 'Failed to Retrieve Formulas - 23.1' })
            this.formula_table.items = []
          }
          this.total_formulas = p.total?.Total ?? 'Error'
         })
        .catch((err) => {
          EventBus.$emit('error', { message: 'Failed to Retrieve Formulas - 23' })
          console.log(err)
        })
        .finally(() => {
          this.formula_table.loading = false
        })
    },
    create_pid_from_array(data) {
      return data.map(p => { return { fid: `${p.number}${p.sequence.toString().padStart(2, "0")}-${p.variant.toString().padStart(2, "0")}`, ...p } })
    },
    // trigger a formula item lockout
    editFormula: function (e, i) {
      this.formula_table.loading = true
      let index = i?.item?.idx ?? e.idx
      let desc = i?.item?.description ?? e.description
      // check if the formula is locked
      axios.get(`${this.$store.getters.NODEURL}/formula/status/${index}`, { withCredentials: true })
        .then((res) => {
          if(res.data.locked && res.data.result.editing_by === this.$store.getters.userPID) {
            EventBus.$emit('notify', { message: `Editing your locked formula.` })
            this.$router.push(`/formulations/editor/${index}`)
          } else if(res.data.locked && res.data.result.editing_by != this.$store.getters.userPID) {
            let p = res.data.result
            EventBus.$emit('error', { message: `${p.First} ${p.Last} has this formula locked while editing.` })
          } else {
            EventBus.$emit('notify', { message: `This formula is now locked.` })
            EventBus.$emit('activity', { message: `Editing ${desc}` })
            // this.firebase_lock_formula(index)
            this.$router.push(`/formulations/editor/${index}`)
          }
        })
        .catch((err) => { console.error(err) })
        .finally(() => {
          // should not technically make it this far
          this.formula_table.loading = false
        })
    },
    firebase_lock_formula(fid) {
      let p = this.formula_table.items.find(v => v.idx === fid).fid
      FormulaLockDataService.create(p, {
        by: this.$store.getters.userPID,
        when: Date.now(),
        name: this.$store.getters.initial_name
      })
    },
    async confirm_clone_action(item) {
      const msg = `Are you certain you want to clone this formula, incrementing the variant's formula number?`
        if (await this.$refs.confirm.open("Formulations", msg)) {
          this.clone_formula(item)
        }
    },
    /* eslint-disable no-unreachable */
    clone_formula (item) {
      this.formula_table.loading = true
      axios.post(`${this.$store.getters.NODEURL}/formula/clone/${item.idx}`, { withCredentials: true })
        .then((res) => {
          EventBus.$emit('notify', { message: `Formula Cloned` })
          this.request_formulation_list()
          console.log(res.data.item)
          // this.new_formulation_dialog = true
          // Object.assign(this.new_formulation, res.data.item)
         })
        .catch((err) => {
          console.log(err)
          EventBus.$emit('error', { message: 'Failed to Clone Formula - 25' })
         })
        .finally(() => { this.formula_table.loading = false })
    },
    is_editing_locked(item) {
      if(item.editing_by === null) return false
      return item.editing_by != this.$store.getters.userPID ? true : false
    },
    formula_info(clone, created, dupe, archive_id) {
      let p = []
      if(dupe) p.push(`Cloned from Formula ID ${clone}`)
      p.push(`Created on ${created}`)
      if(archive_id) p.push(`Formulas Archive ID ${archive_id}`)
      return p
    },
    componentQuery() {
      this.search.busy = true
      this.search.filter = ''
      axios.post(`${this.$store.getters.NODEURL}/formula/com/query`, { query: this.search.search }, { withCredentials: true })
        .then(response => {
          this.search.items = response.data.results
        })
        .catch(e => {
          console.error(e)
          EventBus.$emit('error', { message: `Search Failed - 47` })
          this.results = []
        })
        .finally(() => { this.search.busy = false })
    },
    component_select(e, i) {
      this.formulation.items.push({
        item: i.item.text,
        code: i.item.value,
        rmc: i.item.cost,
        ciu: 0,
        value: 0
      })
      this.search.filter = ''
      this.search.search = ''
    },
  }
}
</script>

<style scoped>
 .v-card { display: flex !important; flex-direction: column; }
 .v-card__text { flex-grow: 1; overflow: auto; }
 .upper input { text-transform: uppercase; }
</style>

<style scoped>
 .switch { margin-top:0!important;padding-top:0!important; }
 .switch >>> .v-label.theme--light { margin-top:-1px; color:#cccccc!important;font-size:11pt; }
 >>> tr.v-data-table__selected { background-color: #eeeeee!important;}
 >>> :not(.v-select).v-text-field input[readonly="readonly"] { color: blue; }
 >>> .v-text-field__prefix { color:#cccccc; }
 .no_selection { user-select: none; -webkit-user-select: none; -moz-user-select: none; -khtml-user-select: none; -ms-user-select: none; }
</style>
